<template>
  <div class='pb-8 pl-2 lg:pl-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>업종관리</h1>
      </div>
    </portal>
    <div class='flex flex-row my-6'>
      <button
        class='border rounded-md border-gray-50 py-2 px-3 text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        @click='editData("add")'>
        <plus-circle-icon class='inline-block'/>
        Add Industry
      </button>
    </div>
    <div class='overflow-auto'>
      <table class='text-sm table-fixed w-full lg:w-auto'>
        <thead>
          <tr class='text-left uppercase tracking-wide border-b'>
            <table-header-filter-text
              v-bind:filter-text.sync='filterCode'
              defaultLabel='업종코드'
              class='px-2 py-2 text-left w-1/2 lg:w-64' />
            <table-header-filter-text
              v-bind:filter-text.sync='filterName'
              defaultLabel='업종명'
              class='px-2 py-2 text-left w-1/2 lg:w-64' />
          </tr>
        </thead>
        <tbody>
          <tr v-for='industry in filteredData'
            @click='clickData(industry)'
            @dblclick='editData("update")'
            :class='highlightRow(industry.code)'
            :key='`industry-${industry.code}`'>
            <td class='px-2 py-2 text-left'>{{ industry.code }}</td>
            <td class='px-2 py-2 text-left'>{{ industry.name }}</td>
          </tr>
          <tr v-if='!filteredData.length'>
            <td colspan='2'>
              <div class='text-center text-gray-500 py-4 lg:py-16 mt-2 bg-gray-100'>
                No industries found.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    <portal to='modals'>
      <industry-edit
        class='px-8'
        :target='target'
        :mode='editMode'
        @done-editing='doneEdit' />
    </portal>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState }    from 'vuex'
import { PlusCircleIcon }          from '@vue-hero-icons/outline'
import TableHeaderFilterText       from '@/components/TableHeaderFilterText.vue'
import IndustryEdit                from '@/views/investment_ledger/IndustryEdit.vue'
import StringHelpers               from '@/utils/string-helpers'

export default {
  name: 'Industries',
  components: {
    TableHeaderFilterText,
    PlusCircleIcon,
    IndustryEdit,
   },
  data () {
    return {
      target: { code: '', name: '' },
      editMode: 'None',
      filterCode: '',
      filterName: '',
    }
  },
  computed: {
    ...mapState('entities', [
      'industries',
    ]),
    filteredData () {
      if (this.filterCode === '' &&
        this.filterName === '') {
        return this.industries
      } else {
        return this.industries.filter(data =>
          (this.filterCode === '' || StringHelpers.stringIncludesInsensitive(data.code, this.filterCode)) &&
          (this.filterName === '' || StringHelpers.stringIncludesInsensitive(data.name, this.filterName))
        )
      }
    },
  },
  methods: {
    ...mapActions('entities', [
      'getIndustries',
    ]),
    clickData (data) {
      this.target = data
    },
    editData (mode) {
      if (mode === 'add') {
        this.target = { code: '', name: '' }
      }
      this.editMode = mode
      if (this.editMode !== 'None') {
        this.$store.dispatch('modalOpen', {height: 20, width: 60})
      }
    },
    doneEdit (resp) {
      this.$store.dispatch('modalClose')
      this.editMode = 'None'
      if (resp !== '') {
        alert(resp)
        this.getIndustries({})
      }
    },
    highlightRow (code) {
      return code === this.target.code ? 'bg-blue-100 border-b' : 'border-b'
    },
  },
  mounted () {
    this.getIndustries({})
  },
}
</script>
