<template>
  <div>
    <portal to='modal-header'>
      <h1 class='text-2xl font-semibold px-8 py-8'>업종관리</h1>
    </portal>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>업종코드</label>
      <input type='text' class='form-input' v-model='ksic.code'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>업종명</label>
      <input type='text' class='form-input' v-model='ksic.name'>
    </div>
    <portal to='modal-footer'>
      <div class='flex flex-row justify-between w-full bg-gray-100 px-8 pr-8 py-4 text-sm shadow-lg'>
        <button
          class='border px-6 h-12 text-sm text-white rounded-md font-bold'
          style='background-color: #223645;'
          @click='saveData'>저장</button>
        <div v-if='isUpdate'>
          <button
            name='show-delete-btn'
            class='p-3 text-red-700
            text-sm border border-transparent hover:border-red-400 rounded-md'
            @click='deleteData'>Delete <span class='font-semibold'>{{ksic.name}}</span></button>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import KsicApi         from '@/api/v1/ksic'
import cloneDeep       from 'lodash/cloneDeep'

export default {
  name: 'IndustryEdit',
  components: {
  },
  props: [
    'target',
    'mode',
  ],
  data () {
    return {
      ksic : {code: '', name: '' }
    }
  },
  computed: {
    isUpdate () {
      return this.mode === 'update'
    },
  },
  watch: {
    'mode': {
      handler: function (newVal) {
        if (newVal === 'add')
          this.ksic = { code: '', name: '' }
        else if (newVal === 'update')
          this.ksic = cloneDeep(this.target)
      },
      immediate: true,
    },
  },
  methods: {
    saveData () {
      if (this.mode === 'add') {
        KsicApi.createKsic (this.ksic).then( resp => {
          this.doneEdit (resp)
        })
      } else {
        KsicApi.updateKsic (this.ksic).then( resp => {
          this.doneEdit (resp)
        })
      }
    },
    deleteData () {
      var deleteConfirm = confirm(this.ksic.name + ' 업종코드가 삭제됩니다.')
      if (!deleteConfirm) {
        return
      }
      KsicApi.deleteKsic(this.ksic).then(resp => {
        this.doneEdit (resp)
      })
    },
    doneEdit (resp) {
      this.$emit('done-editing', resp)
    },
  },
  beforeDestroy () {
    this.doneEdit ('')
  },
}
</script>
